export const useMachineStore = defineStore('machine', () => {
  const types = ref([]);
  const brands = ref([]);

  const brandsWithImage = computed(() =>
    brands.value.filter(({ image }) => image)
  );
  const typesWithAllOption = computed(() => [
    { name: 'Alle types', key: 'alle-machines' },
    ...types.value,
  ]);
  const brandsWithAllOption = computed(() => [
    { name: 'Alle merken', key: 'alle-merken' },
    ...brands.value,
  ]);

  function nuxtServerInit() {
    getMachineTypes();
    getMachineBrands();
  }

  async function getMachineTypes() {
    const { find } = useStrapi();

    const {
      data: {
        value: { data },
      },
      error,
    } = await useAsyncData('types', () =>
      find('machine-types', { sort: ['name'] })
    );
    types.value = data;

    if (error.value) {
      // eslint-disable-next-line no-console
      console.error(
        'Something went wrong fetching the types in the store',
        error.value
      );
    }
  }

  async function getMachineBrands() {
    const { find } = useStrapi();

    const {
      data: {
        value: { data },
      },
      error,
    } = await useAsyncData('brands', () =>
      find('machine-brands', { sort: ['name'] })
    );

    brands.value = data;

    if (error.value) {
      // eslint-disable-next-line no-console
      console.error(
        'Something went wrong fetching the types in the store',
        error.value
      );
    }
  }

  nuxtServerInit();

  return {
    types,
    brands,
    brandsWithImage,
    typesWithAllOption,
    brandsWithAllOption,
  };
});
