<template>
  <button
    class="text-base font-medium hover:los-darkest-gray text-wrap"
    :class="{
      'los-darkest-gray': flyOutExpanded,
    }"
    :aria-expanded="flyOutExpanded"
    @mouseenter="$emit('onShowFlyOut')"
    @focus="$emit('onShowFlyOut')"
    @click="$emit('onToggle')"
    @keydown.enter="emit('onToggle')"
    @keydown.shift.tab="$emit('onHideFlyOut')"
  >
    <slot></slot>
  </button>
</template>

<script setup>
defineProps({
  to: {
    type: String,
    default: '',
  },
  flyOutExpanded: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['onShowFlyOut', 'onHideFlyOut', 'onToggle']);
</script>

<style lang="scss" scoped></style>
