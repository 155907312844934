export const useWorkshopStore = defineStore('workshop', () => {
  const workshops = ref([]);

  function nuxtServerInit() {
    getWorkshops();
  }

  async function getWorkshops() {
    const { find } = useStrapi();

    const {
      data: {
        value: { data },
      },
      error,
    } = await useAsyncData('workshop-header-types', () =>
      find('workshop-types', {
        fields: ['name', 'slug'],
        filters: {
          hidden: { $ne: true },
        },
        sort: ['name'],
      })
    );
    workshops.value = data;
    if (error.value) {
      // eslint-disable-next-line no-console
      console.error(
        'Something went wrong fetching the workshops in the store',
        error.value
      );
    }
  }

  nuxtServerInit();

  return {
    workshops,
  };
});
